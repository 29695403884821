import { Controller } from "stimulus";
import { Rive } from "@rive-app/canvas";

export default class extends Controller {
  static values = {
    path: String
  }

  connect() {
    const r = new Rive({
        src: this.pathValue,
        canvas: this.element,
        autoplay: true,
        stateMachines: "State Machine 1",
        onLoad: () => {
          r.resizeDrawingSurfaceToCanvas();
        },
    });
  }
}
