import { formatCurrency } from "../../util/currency";

const _hasData = (data) => data != null && data.length >= 1;
const _1_HOUR_IN_MS = 1 * 60 * 60 * 1000;
const _1_THOUSAND = 1000;

const EXPONENTS_CONFIG = {
  maximumDecimalTrailingZeroes: 1,
  significantFigures: 4,
}

const formatterDateTime = new Intl.DateTimeFormat(I18n.locale, {
  year: 'numeric',
  month: 'short',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
  second: 'numeric',
  hourCycle: 'h23',
  timeZoneName: 'short',
});

const WATERMARK_WIDTH = 100;
const WATERMARK_HEIGHT = 22;
export const drawWatermark = (e, isFullChart = false) => {
  const chart = e.target;

  if (!chart.watermark) {
    chart.watermark = chart.renderer.image("https://static.coingecko.com/gecko-logo-full-new-black.png", 0, 0, WATERMARK_WIDTH, WATERMARK_HEIGHT)
                                    .addClass("tw-opacity-50").addClass("dark:tw-opacity-30")
                                    .attr({ zIndex: 3 }).add();
  }

  chart.watermark.attr({
    x: chart.plotWidth - WATERMARK_WIDTH + (chart.plotLeft - 10),
    y: chart.plotHeight + (WATERMARK_HEIGHT * 0.5) - (isFullChart ? 0 : chart.plotHeight * 0.2),
  });
};

export const buildChartDefs = () => ({
    // We have to hardcode the stop-color here instead of in CSS to fix exported images.
    // https://github.com/highcharts/highcharts/issues/17093
    defs: {
      gradient0: {
        tagName: "linearGradient",
        id: "gecko-chart-positive-gradient",
        x1: 0,
        y1: 0,
        x2: 0,
        y2: 1,
        children: [
          {
            tagName: "stop",
            offset: 0,
            style: "stop-color: #00A83E; stop-opacity: 0.4;" // tw-success-500
          }, {
            tagName: "stop",
            offset: 1,
            style: "stop-color: #00A83E; stop-opacity: 0;" // tw-success-500
          }]
      },
      gradient1: {
        tagName: "linearGradient",
        id: "gecko-chart-negative-gradient",
        x1: 0,
        y1: 0,
        x2: 0,
        y2: 1,
        children: [
          {
            tagName: "stop",
            offset: 0,
            style: "stop-color: #FF3A33; stop-opacity: 0.4;" // tw-danger-500
          }, {
            tagName: "stop",
            offset: 1,
            style: "stop-color: #FF3A33; stop-opacity: 0;" // tw-danger-500
          }]
      },
    }
});


export const buildChartConfig = (element, data, options = {}) => {
  let optionLabel = options?.label || "null"
  let optionDisplayMode = options?.displayMode || "usd";
  let optionCurrency = options?.currency || "usd";


  const minPrice = _hasData(data) ? data[0][1] : 0;
  const maxPrice = _hasData(data) ? data[data.length - 1][1] : 0;

  return {
    tooltip: {
      useHTML: true,
      borderRadius: 16,
      padding: 0.5,
      formatter() {
        let content = `<div class="title">${formatterDateTime.format(this.x)}</div> `;

        // Loop through all y-axes and display in tooltip.
        for (let point of this.points) {
          let value;
          if (optionDisplayMode === "percentage") {
            value = `${point.y.toFixed(2)}%`;
          } else {
            value = `${formatCurrency(point.y, optionCurrency)}`
          }

          content += `<div>${point.series.name}: <span class="value">${value}</span></div>`;
        }

        return content;
      }
    },


    navigator: {
      series: {
        colorIndex: 0,
        data: data
      },
      height: 40
    },

    xAxis: {
      minRange: _1_HOUR_IN_MS,
      events: {
        afterSetExtremes: e => {}
      }
    },
    yAxis: [
      {
        type: "linear",
        showLastLabel: true,
        labels: {
          align: "left",
          reserveSpace: true,
          useHTML: true,
          formatter() {
            if (optionDisplayMode === "percentage") {
              return `${this.value}%`;
            } else {
              return formatCurrency(this.value, optionCurrency, false, this.value < 1 ? EXPONENTS_CONFIG : false, this.value >= _1_THOUSAND);
            }
          }
        },
      }
    ],
    series: [
      {
        name: optionLabel,
        type: "area",
        data: data,
        colorIndex: (maxPrice >= minPrice ? "positive" : "negative"),
        threshold: -Infinity,
        dataGrouping: { enabled: false },
        yAxis: 0,
        marker: {
          enabled: false,
          radius: 0
        },
        lineWidth: 1,
        states: {
          hover: {
            lineWidth: 1
          }
        },
      }
    ],


    exporting: false,
    rangeSelector: { enabled: false },
    scrollbar: { enabled: false },
    credits: { enabled: false },


    plotOptions: { series: { animation: false } },
    chart: {
      events: { render: (e) => { drawWatermark(e) } },
      panning: false,
      marginTop: 50,
      renderTo: element.id,
      styledMode: true,
      zoomType: 'x',
    },

    time: { useUTC: false },

    ...buildChartDefs()
  };
}
